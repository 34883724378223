<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header cyan">
        <h2 class="text-white">
          Tanımlı Cihaz Bilgileri
          <a @click="showDeviceInfo = !showDeviceInfo">
            <i v-if="showDeviceInfo" class="fas fa-chevron-down text-white"></i>
            <i v-else class="fas fa-chevron-right text-white"></i>
          </a>
        </h2>
      </div>
      <div class="card-body" v-show="showDeviceInfo">
        <div class="row">
          <div class="col-12">
            <v-autocomplete
              v-model="select"
              :items="devices"
              :loading="devicesLoading"
              label="Cihazlar"
              clearable
              deletable-chips
              multiple
              small-chips
              @change="changeSelectedDevices"
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  :style="'background-color:' + item.color"
                  text-color="white"
                  small
                  close
                  @click:close="removeDevice(item.value)"
                >
                  <i :class="item.icon + ' mr-1'" style="color: white"></i>
                  {{ item.text }}
                </v-chip>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  v-show="item.show"
                >
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span :style="'color:' + item.color">
                          <i
                            :class="item.icon + ' mr-1'"
                            :style="'color:' + item.color"
                          ></i>
                          {{ item.text }}
                        </span>
                        <v-spacer></v-spacer>
                        <v-chip
                          text-color="white"
                          :style="'background-color:' + item.color"
                          small
                        >
                          {{ item.category_name }}
                        </v-chip>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-header {
  padding: 1rem 1.25rem;
}
.fa-chevron-down {
  font-size: 1.45rem;
}
.fa-chevron-right {
  font-size: 1.45rem;
}
</style>

<script>
import { GET_ALL_DEVICES } from "@/core/services/store/devices.module";
import setDeviceTheme from "@/assets/js/functions/set_device_theme.js";
export default {
  name: "defined_device_form",
  props:['selected_devices'],
  data() {
    return {
      showDeviceInfo: true,
      //Tüm Cihazların Tutulduğu Değişken
      devices: [],
      devicesLoading: true,
      select:[]
    };
  },
  methods: {
    //Tanımlı Cihazları Kaldırma
    removeDevice(value) {
      const index = this.select.indexOf(value);
      if (index >= 0) this.select.splice(index, 1);
    },
    changeSelectedDevices(){
      this.$emit('changeSelectedDevices',this.select);
    }
  },
  created() {
    //Tüm Cihazları Getirme
    this.$store.dispatch(GET_ALL_DEVICES).then((response) => {
      response.forEach((item) => {
        let theme = setDeviceTheme.set(item.device_category);
        this.devices.push({
          value: item.measuring_device_id + ":" + item.device_category,
          text: item.measuring_device_id + " - " + item.measuring_location_name,
          group: item.device_category,
          category_name: theme.category_name,
          color: theme.color,
          icon: theme.icon,
          show: true,
        });
      });
      //Cihaz Tipine Göre Sıralama
      this.devices.sort((a, b) => (a.group > b.group ? 1 : -1));
      this.devicesLoading = false;
    });
  },
  watch:{
    selected_devices(devices){
      this.select = devices;
    }
  }
};

</script>